import { FlexBox } from '@codecademy/gamut';
import styled from '@emotion/styled';
import { HorizontalScrollMenu as UnstyledHorizontalScrollMenu } from '@mono/brand';

export const StyledHorizontalScrollMenu = styled(UnstyledHorizontalScrollMenu)`
  max-width: calc(100vw - 4px);
  padding: 1rem;
  margin-left: -1rem;
  transform: translateX(-1rem);
  scroll-padding: 1rem;
`;

export const MobileCard = styled(FlexBox)`
  min-width: 14.75rem;
  height: 100%;
  margin: 0 1rem;
`;
