import { b36uuid } from './ChatBox/helpers/b36uuid';

export type Message = {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: string; // should be set by new Date().toISOString()
};

export function createUserMessage(content: string) {
  return {
    id: b36uuid(),
    role: 'user',
    content,
    timestamp: new Date().toISOString(),
  } as Message;
}
