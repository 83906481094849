import { jsonLdScriptProps } from 'react-schemaorg/dist/src/json-ld';
import { Course, ItemList } from 'schema-dts';

type ConstructPageSchemaPropsType = {
  name: string;
  url: string;
  // "itemListElement" is a schema-dts naming convention that actually means a list of items
  itemListElement: (string | Course)[];
};

const constructPageSchemaForItemList = (arg: ConstructPageSchemaPropsType) => {
  const { name, url, itemListElement } = arg;
  return jsonLdScriptProps<ItemList>({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name,
    url,
    itemListElement,
    numberOfItems: itemListElement.length,
    itemListOrder: 'https://schema.org/ItemListUnordered',
  });
};

export default constructPageSchemaForItemList;
