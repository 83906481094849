import { CurriculumCardData } from '@mono/data/curriculum-cards';
import { Course } from 'schema-dts';

import { getSEODuration } from '~/components/ContainerPages/durations';

import { getBaseUrl } from '../paths';

// for use with newer versions of catalog pages
export const curriculumCardsToSEOCourseItems = (
  currCards: CurriculumCardData[]
): Course[] => {
  return currCards.reduce((acc: Course[], cc: CurriculumCardData) => {
    const isFree = cc.type === 'course' && !cc.pro;
    return [
      ...acc,
      {
        '@type': 'Course',
        name: cc.title,
        url: getBaseUrl() + cc.urlPath,
        description: cc.shortDescription || cc.title,
        isAccessibleForFree: isFree,
        provider: {
          '@type': 'Organization',
          name: 'Codecademy',
          sameAs: 'https://www.codecademy.com/',
        },
        hasCourseInstance: [
          {
            '@type': 'CourseInstance',
            courseMode: 'Online',
            courseWorkload: getSEODuration(cc.timeToComplete || 0),
          },
        ],
        offers: {
          '@type': 'Offer',
          category: isFree ? 'Partially Free' : 'Subscription',
        },
      } as Course,
    ];
  }, []);
};
