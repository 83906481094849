import { FlexBox, Text } from '@codecademy/gamut';
import Image from 'next/image';
import { useWindowSize } from 'react-use';

import { AnonAIChatboxPrompts } from '../../AnonAIPrompts';
import GreetingIcon from '../assets/assistant_greeting.svg';

export const Greeting = ({
  onPromptSelect,
}: {
  onPromptSelect: () => void;
}) => {
  const { width } = useWindowSize();
  return (
    <FlexBox
      justifyContent="left"
      flexDirection="column"
      px={{ _: 0, xs: 16 }}
      pt={{ _: 12, xs: 16 }}
    >
      {width >= 768 && (
        <Image
          src={GreetingIcon}
          alt="Chat bubbles"
          width={100}
          height={87}
          aria-hidden
        />
      )}

      <Text fontSize={{ _: 22, sm: 26 }} as="h3" mb={24} mt={{ _: 0, xs: 24 }}>
        Start a conversation and find learning to match your goals
      </Text>
      <AnonAIChatboxPrompts onPromptSelect={onPromptSelect} />
    </FlexBox>
  );
};
