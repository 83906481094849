import { Box } from '@codecademy/gamut';
import { ColorMode, css, system } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { GuardedCaptchaProvider } from '@mono/ui/captcha';
import { type PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ChatBox } from './ChatBox';
import { ChatBtn } from './ChatBtn';
import { setAnonAIOpen, useAnonAIOpen } from './state';

const OPEN_COSE_TRANSITION_MS = 200;
const DURATION_EASING = `${OPEN_COSE_TRANSITION_MS}ms cubic-bezier(0,0,.2,1.1)`;

const ScaleBox = styled(Box)(
  ({ origin, scale }: { origin: string; scale: number }) =>
    css({
      transformOrigin: origin,
      transform: `scale(${scale})`,
      transition: `transform ${DURATION_EASING}, opacity ${DURATION_EASING}`,
      display: 'flex',
      flexDirection: 'column-reverse',
      pointerEvents: 'none',
      '> *': {
        pointerEvents: 'auto',
      },
    })
);

const scaleBoxBottom = { _: 16, xs: 20, sm: 24, md: 28, lg: 32 };
const scaleBoxRight = { _: 16, xs: 32, sm: 40, md: 48, lg: 56 };

const ChatboxPortalWrapper = styled
  .div(
    system.css({
      position: 'absolute',
      zIndex: 10, // render above digioh and tooltips
      top: 0,
      left: 0,
      right: 0,
      height: 0,
    })
  )
  .withComponent(ColorMode);

const ChatboxPortal = ({ children }: PropsWithChildren) => {
  return ReactDOM.createPortal(
    <ChatboxPortalWrapper mode="light" alwaysSetVariables>
      {children}
    </ChatboxPortalWrapper>,
    document.body
  );
};

export const AnonAIAssistant: React.FC<{
  pageName: string;
}> = ({ pageName }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    /*
     * Enable only in useEffect to prevent rendering on the server and to avoid
     * creating body portal prior to hydration.
     */
    setReady(true);
  }, []);

  const open = useAnonAIOpen();
  const [delayedOpen, setDelayedOpen] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setDelayedOpen(open);
    }, OPEN_COSE_TRANSITION_MS);
    return () => clearTimeout(t);
  }, [open]);

  if (!ready) {
    return null;
  }

  // both are visible during transition
  const showBox = open || delayedOpen;
  const showBtn = !open || !delayedOpen;

  return (
    <>
      <ChatboxPortal>
        <GuardedCaptchaProvider>
          <ScaleBox
            position="fixed"
            bottom={scaleBoxBottom}
            right={scaleBoxRight}
            top={160}
            origin="bottom right"
            scale={open ? 1 : 0}
            opacity={open ? 1 : 0}
          >
            {showBox && <ChatBox pageName={pageName} />}
          </ScaleBox>
        </GuardedCaptchaProvider>
      </ChatboxPortal>
      {/* ChatBtn is outside portal to preserve position in tab order */}
      <ScaleBox
        role="region"
        aria-label="AI Learning Assistant"
        position="fixed"
        bottom={scaleBoxBottom}
        right={scaleBoxRight}
        origin="center"
        scale={open ? 0 : 1}
        opacity={open ? 0 : 1}
        zIndex={10}
      >
        {showBtn && (
          <ChatBtn
            ref={(el) => delayedOpen && el?.focus()}
            onClick={() => setAnonAIOpen(true)}
          />
        )}
      </ScaleBox>
    </>
  );
};
