import {
  Anchor,
  Box,
  ContentContainer,
  FlexBox,
  Input,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { SendIcon, SparkleIcon } from '@codecademy/gamut-icons';
import { DotRegular } from '@codecademy/gamut-patterns';
import { ColorMode } from '@codecademy/gamut-styles';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { createUserMessage } from './AnonAIAssistant/message';
import { setAnonAIOpen, setInjectedMessage } from './AnonAIAssistant/state';
import { anonAIStaticPrompts } from './CatalogHome/const';

const slide = keyframes({
  from: { left: -6000, right: 6000 },
  to: { left: 6000, right: -6000 },
});

const PromptButtonShimmer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: clip;
  border-radius: 4px;
  ::before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: '';
    animation: ${slide} 6s linear ${({ i }: { i: number }) => i * 200}ms
      infinite;
    background: linear-gradient(
      to right,
      transparent 0%,
      var(--color-white-300) 50%,
      transparent 100%
    );
  }
`;

const HomePromptButton = styled(TextButton)`
  justify-content: left;
  padding: 6px 8px;
  height: auto;
  background: var(--color-white-100);
  position: relative;
  border-width: 1px;
  border-color: var(--color-white-400);
  white-space: normal;
  text-align: left;
  width: 100%;
  :not(:focus-visible) {
    color: var(--color-white);
  }
  :before {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
  }
`;

const HubPromptButton = styled(TextButton)`
  justify-content: left;
  padding: 6px 8px;
  height: auto;
  background: var(--color-navy-100);
  border-width: 1px;
  border-color: var(--color-navy-400);
  white-space: normal;
  text-align: left;
  width: 100%;
  :not(:focus-visible) {
    color: var(--color-navy);
  }
  :before {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
  }
`;

const ChatboxPromptButton = styled(TextButton)`
  justify-content: left;
  padding: 8px 16px;
  font-weight: normal;
  height: auto;
  background: var(--color-navy-100);
  border-width: 1px;
  white-space: normal;
  text-align: left;
  width: 100%;
  :not(:focus-visible) {
    color: var(--color-navy);
  }
  :before {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
  }
`;

const AnonAIInput = styled(Input)`
  border: none;
  background: var(--color-white);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  + * svg {
    color: var(--color-text-secondary);
  }
  :focus {
    box-shadow: none;
    outline-offset: 2px;
    outline: 2px solid var(--color-yellow);
  }
`;

const AnonAIInputBox = ({
  mobile,
  placeholder,
}: {
  mobile: boolean;
  placeholder: string;
}) => (
  <ColorMode
    mt={24}
    mode="light"
    width={{ _: '100%', sm: 512, md: 640, xl: 768 }}
    display={
      mobile
        ? { _: 'inline-block', xs: 'none' }
        : { _: 'none', xs: 'inline-block' }
    }
  >
    <AnonAIInput
      placeholder={placeholder}
      type="text"
      icon={SendIcon}
      onKeyUp={(evt: React.KeyboardEvent) => {
        const target = evt.target as HTMLInputElement;
        if (evt.key === 'Enter' && target.value.length) {
          setInjectedMessage(createUserMessage(target.value));
          setAnonAIOpen(true);
          target.value = '';
        }
      }}
    />
  </ColorMode>
);

export const AnonAICatalogHomePrompts = ({
  quizHref,
}: {
  quizHref: string;
}) => (
  <FlexBox
    bg="navy"
    overflow="clip"
    position="relative"
    color="white-600" // this sets the color of the pattern
    mb={32}
  >
    <DotRegular position="absolute" top={0} left={0} right={0} bottom={0} />

    <ContentContainer zIndex={2} py={32}>
      <ColorMode
        mode="dark"
        display="flex"
        bg="navy"
        p={16}
        flexDirection="column"
        alignItems="center"
      >
        <Text as="h1" fontSize={{ _: 34, sm: 44 }}>
          Explore the catalog
        </Text>
        <Text
          variant="p-large"
          mt={8}
          textAlign="center"
          maxWidth={{ _: 320, sm: 512 }}
          fontSize={{ _: 16, xs: 18 }}
        >
          Start a conversation and find learning to match your goals.
        </Text>
        <FlexBox
          as="ul"
          flexDirection={{ _: 'column', sm: 'row' }}
          justifyContent="center"
          mt={24}
          mb={0}
          p={0}
          width={{ _: '100%', sm: 640, xl: '100%' }}
          gap={12}
          flexWrap="wrap"
          gridTemplateColumns="1fr 1fr"
        >
          {anonAIStaticPrompts.map((prompt, i) => (
            <Box key={prompt} as="li" listStyleType="none" display="block">
              <HomePromptButton
                size="small"
                onClick={() => {
                  setInjectedMessage(createUserMessage(prompt));
                  setAnonAIOpen(true);
                }}
              >
                <PromptButtonShimmer i={i} aria-hidden />
                <SparkleIcon
                  aria-hidden
                  mr={8}
                  width={12}
                  height={12}
                  color="blue-400"
                />
                {prompt}
              </HomePromptButton>
            </Box>
          ))}
        </FlexBox>

        <AnonAIInputBox mobile placeholder="Ask our AI Learning Assistant" />
        <AnonAIInputBox
          mobile={false}
          placeholder="Ask your question for our AI Learning Assistant"
        />

        <Text fontFamily="accent" variant="p-small" mt={32}>
          <Text>Not sure where to begin?&nbsp;</Text>
          <Anchor
            variant="interface"
            fontWeight="bold"
            aria-label="Take our quiz"
            href={quizHref}
          >
            Take our quiz &rarr;
          </Anchor>
        </Text>
      </ColorMode>
    </ContentContainer>
  </FlexBox>
);

export const AnonAICatalogHubPrompts = ({ prompts }: { prompts: string[] }) => (
  <FlexBox
    as="ul"
    m={0}
    p={0}
    flexWrap="wrap"
    gap={16}
    flexDirection={{ _: 'column', sm: 'row' }}
  >
    {prompts.map((prompt) => (
      <Box key={prompt} as="li" listStyleType="none" display="block">
        <HubPromptButton
          size="small"
          onClick={() => {
            setInjectedMessage(createUserMessage(prompt));
            setAnonAIOpen(true);
          }}
        >
          <SparkleIcon
            aria-hidden
            mr={8}
            width={12}
            height={12}
            color="blue-500"
          />
          {prompt}
        </HubPromptButton>
      </Box>
    ))}
  </FlexBox>
);

export const AnonAIChatboxPrompts = ({
  onPromptSelect,
}: {
  onPromptSelect: () => void;
}) => (
  <FlexBox
    as="ul"
    m={0}
    p={0}
    flexWrap="wrap"
    gap={16}
    flexDirection={{ _: 'column', sm: 'row' }}
  >
    {anonAIStaticPrompts.map((prompt) => (
      <Box key={prompt} as="li" listStyleType="none" display="block">
        <ChatboxPromptButton
          onClick={() => {
            setInjectedMessage(createUserMessage(prompt));
            onPromptSelect();
          }}
        >
          <SparkleIcon
            aria-hidden
            mr={8}
            width={12}
            height={12}
            color="blue-500"
          />
          {prompt}
        </ChatboxPromptButton>
      </Box>
    ))}
  </FlexBox>
);
