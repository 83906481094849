import { Badge, ToolTip } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import { ChatIcon } from './ChatIcon';

const StyledChatBtn = styled.button(
  css({
    border: 'none',
    padding: 0,
    background: 'none',
    cursor: 'pointer',
    position: 'relative', // Ensure positioning does not affect tooltip
    '&:focus-visible': {
      outline: `0.3rem auto ${theme.colors.primary}`,
    },
    display: 'flex',
    '&:hover': {
      svg: {
        '.icon-fill': {
          fill: theme.colors['navy-200'],
        },
      },
    },
  })
);

/*
 Multiple people have commented that it is odd to continue showing a tooltip
 after the chatbox is closed. Initially, we thought this was inevitable since
 we need to return focus to the button for a11y and we show the tooltip on
 focus. However, technically, we may only need to show the tooltip on
 focus-visible and hover. The selector below hides the tooltip when the button
 is in the "focused but neither focus-visible nor hover" state.
 */
const Wrapper = styled.div`
  position: relative;
  display: flex;
  :not(:hover) [role='tooltip'] + *:not(:has(:focus-visible)) > *:first-child {
    display: none;
  }
`;

const NewBadge = styled(Badge)(
  css({
    zIndex: 1,
    position: 'absolute',
    transform: 'translate(0px, 4px)',
  })
);

export const ChatBtn = forwardRef<HTMLButtonElement, { onClick: () => void }>(
  ({ onClick }, ref) => (
    <Wrapper>
      <ToolTip info="AI Learning Assistant" id="chat-with-ai">
        <StyledChatBtn ref={ref} type="button" onClick={onClick} tabIndex={0}>
          <NewBadge size="sm" variant="accent">
            New
          </NewBadge>
          <ChatIcon />
        </StyledChatBtn>
      </ToolTip>
    </Wrapper>
  )
);
