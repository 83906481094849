export enum CatalogCategoryType {
  Language = 'language',
  Subject = 'subject',
}

export type CategoryData = {
  title: string;
  slug: string;
  categoryType: string;
};
